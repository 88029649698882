<template>
    <div class="main-header">
        <div class="main-header__wrapper">
            <header class="main-header__info">
                <div class="main-header__logo-wrapper">
                    <a href="/" class="main-header__logo"></a>
                </div>
                <div class="main-header__phone-wrapper">
                    <a
                        href="tel:+74951337563"
                        class="main-header__phone"
                    >
                        8 (495) 133 75 63
                    </a>
                </div>
                <div class="main-header__actions">
                    <router-link v-if="showHeaderButton" :to="{ name: 'ContactInfo' }">Получить займ</router-link>
                </div>
            </header>
            <div class="main-header__content">
                <div class="main-header__desc">
                    <h1 class="main-header__title">
                        {{ title }}
                    </h1>
                    <p class="main-header__description">
                        Получи деньги быстро и просто независимо от вашей кредитной истории
                    </p>
                </div>
              <div class="main-header__calc-wrap">
                <div class="main-header__calc">
                    <div class="main-header__calc-main">
                        <div class="main-header__calc-slider">
                            <Calculator v-model="loanSum" :showRangeInfo="3"/>
                            <Days v-model="loanDays"/>
                        </div>
                        <div>
                            <p class="main-header__calc-sum">
                                {{ loanSum | price }} <span>₽</span>
                            </p>
                            <p class="main-header__calc-subtitle">
                                на
                            </p>
                            <p class="main-header__calc-days">
                                {{ loanDays.count }} <span>{{ loanDaysString }}</span>
                            </p>
                        </div>
                    </div>
                    <div>
                        <button
                            class="main-header__calc-action"
                            @click="getLoan"
                        >
                            Получить
                        </button>
                    </div>
                </div>
                <p
                    class="main-header__sub-desc"
                    v-if="$DICTIONARY.beelineText && isBeeline"
                >
                  {{ $DICTIONARY.beelineText }}
                </p>
              </div>
            </div>
            <div class="main-header__advantages-wrapper">
                <ul class="main-header__advantages">
                    <li>
                        5 минут от заявки до денег
                    </li>
                    <li>
                        Шифруем и защищаем данные
                    </li>
                    <li>
                        Займы без отказов и проверок
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import './header.scss'
import Calculator from '@/components/common/calculator/Calculator'
import price from '@/helpers/string/price'
import Days from '@/components/common/days/Days'
import term from '@/helpers/string/term';

export default {
    name: 'Header',
    data() {
        return {
            loanSum: 10000,
            loanDays: {
                type: 'days',
                count: 10
            }
        }
    },
    computed: {
      isBeeline() {
        return Cookies.get('sbg-cpa') === 'beeline'
      },
        showHeaderButton() {
            return Cookies.get('sbg-cpa') !== 'direct'
        },
        title() {
            if (Cookies.get('sbg-cpa') === 'direct') {
                return 'Для подбора займа под 0% заполните анкету'
            }

            return 'Для получения займа под 0% заполните анкету'
        },
        loanDaysString() {
            if (this.loanDays.type === 'days') {
                return term(this.loanDays.count)
            }

            return 'недель'
        }
    },
    methods: {
        getLoan() {
            this.$router.push({
                name: 'ContactInfo'
            })
        }
    },
    components: {
        Days,
        Calculator,
    },
    filters: {
        price
    }
}
</script>