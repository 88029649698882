<template>
    <div>
        <Header/>
        <About/>
        <Steps/>
        <Advantages/>
        <Questions/>
        <Help/>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/main/header/Header'

export default {
    name: 'App',
    created() {
        if(window.history && history.pushState){
            window.history.pushState(-1, null);
            window.history.pushState(0, null);
            window.history.pushState(1, null);
            window.history.go(-1);

            window.onpopstate = (event) => {
                if(event.state == -1){
                    window.location.href = 'http://cashdragon.ru/';
                }
            }
        }
    },
    components: {
        Header,
        Footer: () => import('@/components/common/footer/Footer'),
        Questions: () => import('@/components/main/questions/Questions'),
        About: () => import('@/components/main/about/About'),
        Steps: () => import('@/components/main/steps/Steps'),
        Advantages: () => import('@/components/main/advantages/Advantages'),
        Help: () => import('@/components/main/help/Help'),
    }
}
</script>
