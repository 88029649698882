<template>
    <div class="days">
        <VueSlider
            v-model="localModel"
            :dotSize="28"
            :min="1"
            :max="65"
            :interval="1"
        />
        <div class="days__range-info">
          <span>
            1 день
          </span>
            <span>
                10 недель
              </span>
            <span>
                18 недель
              </span>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import './days.scss';
import {mapGetters} from 'vuex'

export default {
    name: 'Days',
    model: {
        prop: 'model',
        event: 'change',
    },
    props: {
        model: {
            type: Object
        }
    },
    created() {
        this.$emit('change', this.calculator.term)
    },
    computed: {
        ...mapGetters({
            calculator: 'application/calculator'
        }),
        localModel: {
            get() {
                if (this.model.type === 'days') {
                    return this.model.count
                } else {
                    return 32 + (String(this.model.count).substr(-1) * 4)
                }
            },
            set(v) {
                if (v <= 31) {
                    const term = {
                        type: 'days',
                        count: v
                    }
                    this.$emit('change', term)
                    this.$store.commit('application/updateCalculator', { term })
                    return
                }

                let count = 0

                const week = v - 32

                if (week !== 0) {
                    count = Math.floor(week / 8 * 2)
                }

                const term = {
                    type: 'weeks',
                    count: +('1' + count)
                }

                this.$emit('change', term)
                this.$store.commit('application/updateCalculator', { term })
            }
        },
    },
    components: {
        VueSlider
    }
}
</script>